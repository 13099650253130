<template>
  <div>
    <header-title :title="title"></header-title>
    <rs-form :model="formData" :show-message="false" label-width="156px" size="small" :rules="rules" ref="formData" class="form-70 commom-form">
      <rs-row>
        <rs-col :span="12">
          <rs-form-item label="登录名" prop="loginName">
            <rs-input :readonly="formData.id ? true : false" v-model="formData.loginName" :maxlength="20" placeholder="请输入登录名"></rs-input>
          </rs-form-item>
        </rs-col>
        <rs-col :span="12">
          <rs-form-item label="员工姓名" prop="userName">
            <rs-input v-model="formData.userName" maxlength="30" placeholder="请输入员工姓名"></rs-input>
          </rs-form-item>
        </rs-col>
      </rs-row>
      <rs-row>
        <rs-col :span="12">
          <rs-form-item label="性别" prop="sex">
            <rs-select
              :popper-append-to-body="false"
              v-model="formData.sex"
              popper-class="dialog-popper"
              value-key="id"
              ref="selectGender"
              placeholder="性别"
            >
              <rs-option v-for="item in genders" :key="item.id" :label="item.label" :value="item.value"></rs-option>
            </rs-select>
          </rs-form-item>
        </rs-col>
        <rs-col :span="12">
          <rs-form-item label="选择组织" prop="orgSysCode">
            <org-select :orgSelectName="formData.orgName" :organizationSysCode="formData.orgSysCode" @orgSelectChange="orgSelectChange"></org-select>
          </rs-form-item>
        </rs-col>
      </rs-row>
      <rs-row>
        <rs-col :span="12">
          <rs-form-item label="选择岗位" prop="stationSysCode">
            <rs-select
              :popper-append-to-body="false"
              v-model="formData.stationSysCode"
              popper-class="dialog-popper"
              value-key="id"
              multiple
              ref="stationSysCode"
              placeholder="请选择发起部门"
            >
              <rs-option v-for="item in stationList" :key="item.id" :label="item.stationName" :value="item.stationSysCode"></rs-option>
            </rs-select>
            <!-- <userSelect
              :orgSelectName="formData.stationName"
              :organizationSysCode="formData.stationSysCode"
              @orgSelectChange="userSelectChange"
            ></userSelect> -->
          </rs-form-item>
        </rs-col>
        <rs-col :span="12">
          <rs-form-item label="选择状态">
            <rs-select v-model="formData.status" placeholder="请选择状态">
              <rs-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></rs-option>
            </rs-select>
          </rs-form-item>
        </rs-col>
      </rs-row>
      <rs-row>
        <rs-col :span="12">
          <rs-form-item label="手机" prop="mobile">
            <rs-input v-model="formData.mobile" placeholder="请输入手机"></rs-input>
          </rs-form-item>
        </rs-col>
        <rs-col :span="12">
          <rs-form-item label="邮箱">
            <rs-input v-model="formData.email" placeholder="请输入邮箱"></rs-input>
          </rs-form-item>
        </rs-col>
      </rs-row>
    </rs-form>
    <div class="edit-form button-box" style="text-align: right">
      <rs-button size="small" @click="jumpBack(false)">取消</rs-button>
      <rs-button size="small" @click="save" type="primary" :loading="saveLoading">保存</rs-button>
    </div>
  </div>
</template>
<script>
import Http from '@/api/organizationApi'
import Api from '@/api/account'
import utils from '@/utils/utils'
import orgSelect from '@/components/orgSelect'
import headerTitle from '@/components/header'
import userSelect from '@/components/orgSelect/jobChoose'
export default {
  components: {
    orgSelect,
    headerTitle,
    userSelect
  },
  data() {
    return {
      title: '',
      genders: [
        {
          value: 0,
          label: '男'
        },
        {
          value: 1,
          label: '女'
        }
      ],
      options: [
        {
          value: true,
          label: '已启用'
        },
        {
          value: false,
          label: '已停用'
        }
      ],
      userId: '',
      formData: {
        orgSysCode: '',
        orgName: '',
        loginName: '',
        userName: '',
        sex: 0,
        mobile: '',
        email: '',
        status: true,
        stationName: '',
        stationSysCodeStr: '',
        stationSysCode: []
      },
      stations: [],
      // 用户form校验
      rules: {
        orgSysCode: [{ required: true, message: '请选择组织', trigger: 'change' }],
        stationSysCode: [{ required: true, message: '请选择岗位', trigger: 'change' }],
        loginName: [{ required: true, message: '请输入登录名', trigger: 'change' }],
        userName: [{ required: true, message: '请输入真实姓名', trigger: 'change' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        status: [{ required: true, message: '请选择是否有效', trigger: 'change' }],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入手机号'))
              } else if (!/^((\+86)|(86))?1[3456789]\d{9}$/.test(value)) {
                callback(new Error('请输入正确的手机号码'))
              } else {
                callback()
              }
            },
            trigger: 'change'
          }
        ]
      },
      stationList: [],
      selectParams: {
        clearable: true,
        placeholder: '所在组织'
      },
      popoverClass: 'popoverClassSelect',
      saveLoading: false
    }
  },
  async created() {
    this.title = '新建账户'
    if (this.$route.query.id) {
      this.title = '编辑账户'
      this.formData.orgName = this.$route.query.orgName
      this.userId = this.$route.query.id
      await this.handleDetail()
      this.handleStation()
    }
    // await this.initData()
  },
  methods: {
    validate(cb) {
      this.$refs['formData'].validate(cb)
    },
    resetFields() {
      this.$refs.formData.resetFields()
    },
    orgSelectChange(val) {
      if (val.value) {
        this.formData.orgSysCode = val.value || ''
        this.formData.stationSysCode = []
        this.handleStation()
      }
    },
    userSelectChange(val) {
      if (val.length <= 0) {
        return false
      }
      let code = []
      val.forEach((item, index) => {
        code.push(item.sysCode)
      })
      this.formData.stationSysCode = code
    },
    // 获取详情
    async handleDetail() {
      let res = await Api.getUserInfoDetail({ id: this.userId })
      this.formData = res.data.data
      let stationSysCode = res.data.data.stationVoList.map((item) => item.stationSysCode)
      this.formData.stationSysCode = stationSysCode
      this.handleStation()
    },
    // 获取组织下所有岗位
    handleStation() {
      if (!this.formData.orgSysCode) return
      let that = this
      let params = {
        orgSysCode: this.formData.orgSysCode,
        pageNum: 1,
        pageSize: 99999
      }
      Http.getStationListByOrg(params).then((res) => {
        if (res.data.data && res.data.data.length > 0) {
          this.stationList = res.data.data
          this.$forceUpdate()
        } else {
          this.stationList = []
          this.formData.stationSysCode = []
          top.$message.warning('该组织下没有岗位，请重新选择组织')
        }
      })
    },
    save() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          // let orgStation = this.stations.find(item=>this.formData.stationSysCode.indexOf(item.stationSysCode)>=0)
          // console.log(this.formData.stationSysCode, orgStation)
          // if(!orgStation) {
          //     top.$message.warning('请选择至少一个已选组织下的岗位')
          //     return
          // }
          this.saveLoading = true
          Api.updateInfo(this.formData)
            .then((res) => {
              this.saveLoading = false
              this.$message({
                message: '操作成功！',
                type: 'success'
              })
              this.jumpBack()
            })
            .catch((err) => {
              this.saveLoading = false
            })
        } else {
          top.$message.warning('基础信息未填写完整')
        }
      })
    },
    jumpBack() {
      this.$router.back()
    },
    initData() {
      // let params = {}
      // let data = []
      // let user = utils.getSession('userInfo')
      // Http.queryStationListAll({ companyCode: user.companyVo.companyCode }).then((res) => {
      //   this.stationList = res.data.data
      // })
    }
  }
}
</script>
<style lang="less" scoped>
.demo-ruleForm {
  width: 400px;
}
</style>