var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("header-title", { attrs: { title: _vm.title } }),
      _c(
        "rs-form",
        {
          ref: "formData",
          staticClass: "form-70 commom-form",
          attrs: {
            model: _vm.formData,
            "show-message": false,
            "label-width": "156px",
            size: "small",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "rs-row",
            [
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "rs-form-item",
                    { attrs: { label: "登录名", prop: "loginName" } },
                    [
                      _c("rs-input", {
                        attrs: {
                          readonly: _vm.formData.id ? true : false,
                          maxlength: 20,
                          placeholder: "请输入登录名",
                        },
                        model: {
                          value: _vm.formData.loginName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "loginName", $$v)
                          },
                          expression: "formData.loginName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "rs-form-item",
                    { attrs: { label: "员工姓名", prop: "userName" } },
                    [
                      _c("rs-input", {
                        attrs: {
                          maxlength: "30",
                          placeholder: "请输入员工姓名",
                        },
                        model: {
                          value: _vm.formData.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "userName", $$v)
                          },
                          expression: "formData.userName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "rs-row",
            [
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "rs-form-item",
                    { attrs: { label: "性别", prop: "sex" } },
                    [
                      _c(
                        "rs-select",
                        {
                          ref: "selectGender",
                          attrs: {
                            "popper-append-to-body": false,
                            "popper-class": "dialog-popper",
                            "value-key": "id",
                            placeholder: "性别",
                          },
                          model: {
                            value: _vm.formData.sex,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "sex", $$v)
                            },
                            expression: "formData.sex",
                          },
                        },
                        _vm._l(_vm.genders, function (item) {
                          return _c("rs-option", {
                            key: item.id,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "rs-form-item",
                    { attrs: { label: "选择组织", prop: "orgSysCode" } },
                    [
                      _c("org-select", {
                        attrs: {
                          orgSelectName: _vm.formData.orgName,
                          organizationSysCode: _vm.formData.orgSysCode,
                        },
                        on: { orgSelectChange: _vm.orgSelectChange },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "rs-row",
            [
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "rs-form-item",
                    { attrs: { label: "选择岗位", prop: "stationSysCode" } },
                    [
                      _c(
                        "rs-select",
                        {
                          ref: "stationSysCode",
                          attrs: {
                            "popper-append-to-body": false,
                            "popper-class": "dialog-popper",
                            "value-key": "id",
                            multiple: "",
                            placeholder: "请选择发起部门",
                          },
                          model: {
                            value: _vm.formData.stationSysCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "stationSysCode", $$v)
                            },
                            expression: "formData.stationSysCode",
                          },
                        },
                        _vm._l(_vm.stationList, function (item) {
                          return _c("rs-option", {
                            key: item.id,
                            attrs: {
                              label: item.stationName,
                              value: item.stationSysCode,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "rs-form-item",
                    { attrs: { label: "选择状态" } },
                    [
                      _c(
                        "rs-select",
                        {
                          attrs: { placeholder: "请选择状态" },
                          model: {
                            value: _vm.formData.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "status", $$v)
                            },
                            expression: "formData.status",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c("rs-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "rs-row",
            [
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "rs-form-item",
                    { attrs: { label: "手机", prop: "mobile" } },
                    [
                      _c("rs-input", {
                        attrs: { placeholder: "请输入手机" },
                        model: {
                          value: _vm.formData.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "mobile", $$v)
                          },
                          expression: "formData.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "rs-form-item",
                    { attrs: { label: "邮箱" } },
                    [
                      _c("rs-input", {
                        attrs: { placeholder: "请输入邮箱" },
                        model: {
                          value: _vm.formData.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "email", $$v)
                          },
                          expression: "formData.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "edit-form button-box",
          staticStyle: { "text-align": "right" },
        },
        [
          _c(
            "rs-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.jumpBack(false)
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "rs-button",
            {
              attrs: {
                size: "small",
                type: "primary",
                loading: _vm.saveLoading,
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }