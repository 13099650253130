<template>
  <div>
    <rs-org-select
      v-model="organizationSysCode"
      :label="label"
      nodeKey="sysCode"
      multiple
      :check-strictly="true"
      :treeData="treeParams.data"
      :props="treeParams.props"
      @change="orgSelectChange"
      ref="treeSelect"
      placeholder="请选择发起部门"
    ></rs-org-select>
  </div>
</template>

<script>
import Api from '@/api/organizationApi'
export default {
  name: 'orgSelect',
  props: {
    organizationSysCode: {
      type: Array,
      default: ''
    },
    orgSelectName: {
      type: String,
      default: ''
    }
  },
  computed: {
    label() {
      return this.name
    }
  },
  data() {
    return {
      treeParams: {
        clickParent: true,
        filterable: true,
        'check-strictly': true,
        'default-expand-all': true,
        'expand-on-click-node': false,
        // 'check-on-click-node': true,
        'highlight-current': true,
        // 'show-checkbox': true,
        data: [],
        props: {
          children: 'children',
          label: 'name',
          disabled: 'disabled',
          value: 'sysCode'
        }
      }
    }
  },
  methods: {
    initTree(treeData) {
      let params = {}
      let data = []
      Api.queryOrganizationJobTree(params)
        .then((res) => {
          data = res.data.data ? res.data.data : []
          this.treeParams.data = []
          data.forEach((element) => {
            this.treeParams.data.push(this.handleMapTree(element))
          })
          this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data)
        })
        .catch((err) => {})
    },
    // 对树形数据进行处理disabled
    handleMapTree(el) {
      const haveChildren = Array.isArray(el.children) && el.children.length > 0
      return {
        disabled: el.status ? false : true,
        id: el.id,
        name: el.name,
        label: el.name,
        showCode: el.showCode,
        sysCode: el.sysCode,
        children: haveChildren ? el.children.map((i) => this.handleMapTree(i)) : null
      }
    },
    orgSelectChange(val) {
      this.$emit('orgSelectChange', val)
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.initTree()
  }
}
</script>
<style scoped>
/* @import url(); 引入css类 */
</style>
