var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("rs-org-select", {
        ref: "treeSelect",
        attrs: {
          label: _vm.label,
          nodeKey: "sysCode",
          multiple: "",
          "check-strictly": true,
          treeData: _vm.treeParams.data,
          props: _vm.treeParams.props,
          placeholder: "请选择发起部门",
        },
        on: { change: _vm.orgSelectChange },
        model: {
          value: _vm.organizationSysCode,
          callback: function ($$v) {
            _vm.organizationSysCode = $$v
          },
          expression: "organizationSysCode",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }